.contact-page {
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.contact-form {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: none;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.contact h2 {
  text-align: left;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1.5em;
  font-weight: normal;
  color: #333;
  margin-bottom: 1rem;
}

.contact-form label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-weight: normal;
  color: #333;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
}

.contact-form input,
.contact-form textarea {
  width: -webkit-fill-available;
  padding: 1rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.submit-button,
.cancel-button {
  padding: 0.75rem 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  color: #000;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1.0em;
  text-align: center;
}

.submit-button:hover,
.cancel-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (prefers-color-scheme: light) {
  body {
    background-color: white;
    color: black;
  }
  header {
    background-color: white;
    color: black;
    }

}

@media (prefers-color-scheme: dark) {
  section {
    background-color: black;
    color: white;
  }
  header {
    background-color: black;
    color: white;
  }
  .contact-form{
    background-color: black;
    color: #fff;
  }

  .submit-button, .cancel-button{
    background-color: black;
    color: #fff;
  }

  .contact-form label, .contact-form h2{
    color:#fff;
  }
  .contact-form input, .contact-form textarea {
    background-color: #fff;
    border: 1px solid #ccc;
  }
}