@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes pulseBorderColor {
  0%, 100% {
    border-color: #000;
  }
  50% {
    border-color: #ccc;
  }
}

@keyframes pulseTextColorWave {
  0%, 100% {
    color: #000;
  }
  50% {
    color: #ccc;
  }
}

a:visited { text-decoration: none; color:#fff; }
a:hover { text-decoration: none; color: #ccc; }
a:focus { text-decoration: none; color: #ccc; }
a:hover, a:active { text-decoration: none; color:#ccc }

.puzzle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.puzzle-instructions {
  font-size: 2.5em;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.puzzle-instructions span {
  animation: pulseTextColorWave 3s infinite ease-in-out;
  display: inline-block;
}

.puzzle-instructions span:nth-child(1) {
  animation-delay: 0s;
}

.puzzle-instructions span:nth-child(2) {
  animation-delay: 0.1s;
}

.puzzle-instructions span:nth-child(3) {
  animation-delay: 0.2s;
}

.puzzle-instructions span:nth-child(4) {
  animation-delay: 0.3s;
}

.puzzle-instructions span:nth-child(5) {
  animation-delay: 0.4s;
}

.puzzle-instructions span:nth-child(6) {
  animation-delay: 0.5s;
}

.puzzle-instructions span:nth-child(7) {
  animation-delay: 0.6s;
}

.puzzle-instructions span:nth-child(8) {
  animation-delay: 0.7s;
}

.jigsaw-puzzle {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
  animation: pulseBorderColor 2s infinite ease-in-out;
}

.puzzle-container {
  position: relative;
  width: 100%;
  padding-top: 75%;
}

.jigsaw-puzzle__piece {
  position: absolute;
  border: 1px solid #cecece;
  cursor: pointer;
  z-index: 1;
  user-select: none;
  background-size: cover;
}

.jigsaw-puzzle__piece.jigsaw-puzzle__piece--unsolved {
  animation: pulse 2s infinite ease-in-out;
}

.jigsaw-puzzle__piece.jigsaw-puzzle__piece--solved {
  border: 1px solid #cecece;
  z-index: 0;
}

.jigsaw-puzzle__piece.jigsaw-puzzle__piece--dragging {
  z-index: 5;
  box-shadow: 1px 1px 5px 0px #00000091;
}

body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  animation: fadeIn 1s ease-in-out;
}

body.no-scroll {
  overflow: hidden;
}

header, .about h1, .description, .content {
  font-family: inherit;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  background-color: #fff;
  border: none;
  margin-top: 20px;
}

.language-switch {
  margin-top: 10px;
}

.language-switch button {
  background: none;
  border: none;
  color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  font: inherit;
  font-weight: bold;
  outline: inherit;
}

.language-switch button:hover{
  color: #555
}

.language-switch button.selected {
  color: #333;
}

.about {
  text-align: center;
  padding: 10px 20px 20px 10px;
}

.about h1 {
  font-size: 2.0em;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: #333;
  border-bottom: none;
  padding-bottom: 10px;
  font-family: inherit;
}

.about .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px 20px 0px;
}

.about-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border: none;
}

.description {
  max-width: 800px;
  text-align: left;
  padding: 20px;
  line-height: 1.6;
  font-size: 1em;
  color: #333;
  border: none;
  padding: 0 20px;
  font-weight: lighter;
}

.description .name {
  font-size: 1.2em;
  margin-bottom: 0;
  padding-bottom: 0px;
  margin-top: 0;
}

.description .jobTitle {
  font-size: 1.1em;
  margin-top: 0;
}

.description p {
  font-size: 0.9em;
  margin-bottom: 10px;
  border-bottom: none;
}

.description .weather-city {
  font-size: 0.8em;
}

@media (min-width: 768px) {
  .about .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
  }

  .about-image {
    max-width: 45%;
    border: none;
  }

  .description {
    max-width: 55%;
    border: none;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

 
  .description-top {
    margin-bottom: auto;
  }

  .description-bottom {
    margin-top: 2rem;
    padding-bottom: 5px;
  }
}

@media (max-width: 550px) {
  .about .content {
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding: 0;
  }

  .description {
    text-align: left;
    padding-left: 0px;
    padding-bottom: 50px;
  }

  .puzzle-instructions {
    font-size: 1.5em;
    justify-content: center;
  }

  .about h1 {
    font-size: 1.5em;
    justify-content: center;
  }

  .weather-city {
    padding-top: 50px;
  }
}

.captcha-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.puzzle-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: auto;
  max-width: 600px;
  aspect-ratio: 3 / 2;
  margin-bottom: 20px;
}

.puzzle-piece {
  width: 100%;
  height: 100%;
  background-size: 600px 400px;
  cursor: pointer;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border: none;
  text-align: center;
  padding: 10px 0;
}

.banner {
  font-size: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 30px;
  bottom: 8px;
  border: none;
  backface-visibility: hidden;
  transform: translateZ(0);
  contain: content;
}

.banner-content {
  display: inline-block;
  animation: scroll 6000s linear infinite;
  will-change: transform;
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.banner a {
  text-decoration: none;
  color: inherit;
  margin: 0 10px;
  cursor: pointer;
  border: none;
}

.banner a:nth-child(odd) {
  animation: pulseColorOdd 3s infinite ease-in-out;
  animation-delay: 1s;
}

.banner a:nth-child(even) {
  animation: pulseColorEven 3s infinite ease-in-out;
  animation-delay: 2s;
}

@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes pulseColorOdd {
  0%, 100% {
    color: inherit;
  }
  50% {
    color: #ccc;
  }
}

@keyframes pulseColorEven {
  0%, 100% {
    color: inherit;
  }
  50% {
    color: #aaa;
  }
}

.sent-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 2.5em;
  font-weight: 300;
  letter-spacing: 1px;
  color: #333;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pulse-fade-out {
  animation: pulse 3s ease-in-out infinite, fadeOut 1.5s 2s forwards;
}

.fade-out {
  animation: fadeOut 0.3s ease-in-out forwards;
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.hidden {
  display: none;
}

.captcha-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-out {
  animation: fadeOut 0.3s forwards;
}

.fade-in {
  animation: fadeIn 0.3s forwards;
}

.hidden {
  display: none;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: white;
    color: black;
  }
  header {
    background-color: white;
    color: black;
    }
  a:visited { text-decoration: none; color: #333; }
  a:hover { text-decoration: none; color: #333; }
  a:focus { text-decoration: none; color: #ccc; }
  a:hover, a:active { text-decoration: none; color:#ccc }

}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    color: white;
  }
  header {
    background-color: black;
    color: white;
  }
  footer {
    background-color: black;
    color: white;
  }
  div {
    color: #aaa;
  }
  .about h1 {
    color: #aaa;
  }

  .language-switch {
    color: #aaa
  }

  .sent-screen {
    background-color: black;
    color: white
  }
  a:visited { text-decoration: none; color:#ccc; }
  a:hover { text-decoration: none; color: #fff; }
  a:focus { text-decoration: none; color: #fff; }
  a:hover, a:active { text-decoration: none; color:#fff }

  @keyframes pulseColorOdd {
    0%, 100% {
      color: #444444;
    }
    50% {
      color: #ccc;
    }
  }
  
  @keyframes pulseColorEven {
    0%, 100% {
      color: #444444;
    }
    50% {
      color: #ccc;
    }
  }

  @keyframes pulseBorderColor {
    0%, 100% {
      border-color: #444444;
    }
    50% {
      border-color: #ccc;
    }
  }
  
  @keyframes pulseTextColorWave {
    0%, 100% {
      color: #444444;
    }
    50% {
      color: #ccc;
    }
  }
}